import React, { useState } from 'react';
import T from 'prop-types';
import { Link, graphql } from 'gatsby';

import Layout from '../../components/layout';
import {
  Inpage,
  InpageHeader,
  InpageHeaderInner,
  InpageHeadline,
  InpageTitle,
  InpageBody,
  InpageSuptitle
} from '../../styles/inpage';
import TopicsNav from '../../components/topics-nav';
import DataLoadButton from '../blog-hub/data-load-button';
import ProjectList from './project-list';

import { PROG_PER_PAGE, ProjectsListWrapper } from '.';

export default function ProjectsHubTopic({ data, location, pageContext }) {
  const projects = data.allProject.nodes;
  const { topics, currentTopic } = pageContext;

  const [page, setPage] = useState(1);

  const projectsVisible = projects.slice(0, page * PROG_PER_PAGE);

  return (
    <Layout location={location} title='Projects'>
      <Inpage as='section'>
        <InpageHeader>
          <InpageHeaderInner>
            <InpageHeadline>
              <InpageTitle>{currentTopic.label}</InpageTitle>
              <InpageSuptitle>
                <Link to='/projects' title='View Projects page'>
                  Topic
                </Link>
              </InpageSuptitle>
            </InpageHeadline>

            <TopicsNav
              topics={topics}
              current={currentTopic}
              base='/projects'
            />
          </InpageHeaderInner>
        </InpageHeader>
        <InpageBody>
          <ProjectsListWrapper>
            <ProjectList items={projectsVisible} />
            <DataLoadButton
              contentType='projects'
              count={projectsVisible.length}
              total={projects.length}
              hasMore={page * PROG_PER_PAGE < projects.length}
              // Given the small amount of posts they don't need to be loaded.
              // They're paginated so the page doesn't get too long.
              isLoading={false}
              onClick={() => setPage((p) => p + 1)}
            />
          </ProjectsListWrapper>
        </InpageBody>
      </Inpage>
    </Layout>
  );
}

ProjectsHubTopic.propTypes = {
  data: T.object,
  location: T.object,
  pageContext: T.shape({
    currentTopic: T.object,
    topics: T.array
  })
};

export const pageQuery = graphql`
  query ProjectByTopics($currentTopicLabel: [String]!) {
    allProject(
      filter: {
        published: { eq: true }
        frontmatter: { topics: { in: $currentTopicLabel } }
      }
      sort: {
        order: [DESC, ASC]
        fields: [frontmatter___weight, frontmatter___title]
      }
    ) {
      nodes {
        id
        slug
        frontmatter {
          title
          teaser
          client {
            name
            url
          }
          media {
            ...CardImageProjectFluid
          }
        }
      }
    }
  }
`;
