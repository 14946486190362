import React, { useState } from 'react';
import T from 'prop-types';
import { graphql } from 'gatsby';
import styled from 'styled-components';

import Layout from '../../components/layout';
import {
  Inpage,
  InpageHeader,
  InpageHeaderInner,
  InpageHeadline,
  InpageTitle,
  InpageBody,
  InpageLead
} from '../../styles/inpage';
import UniversalGridder from '../../styles/universal-gridder';
import TopicsNav from '../../components/topics-nav';
import DataLoadButton from '../blog-hub/data-load-button';
import ProjectList from './project-list';

export const ProjectsListWrapper = styled(UniversalGridder).attrs({
  as: 'section',
  grid: {
    smallUp: ['full-start', 'full-end'],
    mediumUp: ['full-start', 'full-end'],
    largeUp: ['full-start', 'full-end']
  }
})`
  grid-column: 1 / -1;
  background: transparent;
`;

export const PROG_PER_PAGE = 12;

export default function ProjectsHub({ data, location, pageContext }) {
  const projects = data.allProject.nodes;
  const { topics } = pageContext;

  const [page, setPage] = useState(1);

  const projectsVisible = projects.slice(0, page * PROG_PER_PAGE);

  return (
    <Layout location={location} title='Projects'>
      <Inpage as='section'>
        <InpageHeader>
          <InpageHeaderInner>
            <InpageHeadline>
              <InpageTitle>Our projects</InpageTitle>
              <InpageLead>A curated collection of our projects.</InpageLead>
            </InpageHeadline>

            <TopicsNav topics={topics} base='/projects' />
          </InpageHeaderInner>
        </InpageHeader>
        <InpageBody>
          <ProjectsListWrapper>
            <ProjectList items={projectsVisible} />
            <DataLoadButton
              contentType='projects'
              count={projectsVisible.length}
              total={projects.length}
              hasMore={page * PROG_PER_PAGE < projects.length}
              // Given the small amount of posts they don't need to be loaded.
              // They're paginated so the page doesn't get too long.
              isLoading={false}
              onClick={() => setPage((p) => p + 1)}
            />
          </ProjectsListWrapper>
        </InpageBody>
      </Inpage>
    </Layout>
  );
}

ProjectsHub.propTypes = {
  data: T.object,
  location: T.object,
  pageContext: T.shape({
    currentTopic: T.object,
    topics: T.array
  })
};

export const pageQuery = graphql`
  query {
    allProject(
      filter: { archived: { eq: false }, published: { eq: true } }
      sort: {
        order: [DESC, ASC]
        fields: [frontmatter___weight, frontmatter___title]
      }
    ) {
      nodes {
        id
        slug
        frontmatter {
          title
          teaser
          client {
            name
            url
          }
          media {
            ...CardImageProjectFluid
          }
        }
      }
    }
  }
`;
