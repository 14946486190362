import React from 'react';
import T from 'prop-types';
import styled from 'styled-components';
import { Button } from '@devseed-ui/button';
import { glsp } from '@devseed-ui/theme-provider';

const MetaActions = styled.nav`
  position: relative;
  z-index: 10;
  grid-column: content-start / content-end;
  display: flex;
  flex-flow: column;
  align-items: center;

  > *:not(:last-child) {
    margin-bottom: ${glsp()};
  }
`;

const MetaInfo = styled.p`
  font-variation-settings: 'wdth' 96, 'wght' 512;
  font-size: 0.875rem;
  line-height: 1rem;
  opacity: 0.64;
  text-transform: uppercase;
`;

const ButtonLoadMore = styled(Button)`
  min-width: 16rem;
`;

export default function DataLoadButton(props) {
  const {
    count,
    total,
    hasMore,
    isLoading,
    onClick,
    contentType = 'posts'
  } = props;

  return (
    <MetaActions>
      <MetaInfo>
        Showing {count} of {total} {contentType}
      </MetaInfo>
      <ButtonLoadMore
        variation='primary-fill'
        size='xlarge'
        title='Load more'
        disabled={!hasMore || isLoading}
        onClick={onClick}
      >
        Load more
      </ButtonLoadMore>
    </MetaActions>
  );
}

DataLoadButton.propTypes = {
  contentType: T.string,
  count: T.number,
  total: T.number,
  hasMore: T.bool,
  isLoading: T.bool,
  onClick: T.func
};
