import React from 'react';
import { Link } from 'gatsby';
import T from 'prop-types';
import styled, { keyframes } from 'styled-components';
import { listReset, glsp, themeVal, media } from '@devseed-ui/theme-provider';

const underliner = keyframes`
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
`;

const TopicsNavSelf = styled.nav`
  grid-row: 2;
  grid-column: 1 / -1;
`;

const TopicsList = styled.ul`
  ${listReset()}
  display: flex;
  flex-flow: row wrap;
  gap: ${glsp(0.5, 2)};

  ${media.largeUp`
    gap: ${glsp(1, 2)};
  `}
`;

const TopicLink = styled(Link)`
  position: relative;
  display: block;
  line-height: 2rem;
  color: ${themeVal('color.base')};
  font-size: 1.5rem;
  font-family: ${themeVal('type.heading.family')};
  font-variation-settings: ${themeVal('type.heading.settings')};
  transition: opacity 0.24s ease-in-out;

  &,
  &:visited {
    color: ${themeVal('type.base.color')};
  }

  &:hover {
    opacity: 0.64;
  }

  &::after {
    position: absolute;
    bottom: 0;
    left: 50%;
    content: '';
    width: 0;
    height: 4px;
    background: ${themeVal('color.primary')};
    transform: translate(-50%, 0);
  }

  &.active::after {
    animation: ${underliner} ease-in 0.24s 0.32s forwards;
  }
`;

export default function TopicsNav({ topics, base }) {
  return (
    <TopicsNavSelf>
      <TopicsList>
        {topics.map((t) => (
          <li key={t.id}>
            <TopicLink
              to={t.id === 'all' ? base : `${base}/topics/${t.id}`}
              activeClassName='active'
            >
              {t.label}
            </TopicLink>
          </li>
        ))}
      </TopicsList>
    </TopicsNavSelf>
  );
}

TopicsNav.propTypes = {
  topics: T.array,
  base: T.string
};
