import React, { useEffect } from 'react';
import T from 'prop-types';
import styled from 'styled-components';
import get from 'lodash.get';
import { Masonry } from 'masonic';

import useMediaAware from '../../utils/media-query-aware-hook';
import { CardProject } from '../../components/cards';
import {
  MasonryItemsListElement,
  MasonryItemsWrapper,
  MasonryItemsWrapperInner
} from '../../styles/masonry';

// Randomize heights on build so the cards have some height variability.
const randomHeights = [
  '36rem',
  '28rem',
  '34rem',
  '30rem',
  '25rem',
  'auto',
  '40rem'
];

const CardProjectMixHeight = styled(CardProject)`
  min-height: ${({ index }) => randomHeights[index % randomHeights.length]};

  > * {
    margin-bottom: 0;
  }
`;

const masonryRenderFn = ({ index, data }) => {
  const { slug } = data;
  const { title, media, client } = data.frontmatter;
  const cardImage = get(media, 'card.url.childImageSharp.fluid');

  return (
    <CardProjectMixHeight
      index={index}
      title={title}
      slug={slug}
      cardImage={cardImage}
      client={client}
      skin='dark'
    />
  );
};

export default function ProjectList(props) {
  const { items } = props;
  const [ref, media] = useMediaAware();

  // In this case our reference is the body, and not a specific element.
  useEffect(() => {
    ref(document.body);
  }, [ref]);

  return (
    <MasonryItemsWrapper>
      <MasonryItemsWrapperInner>
        <Masonry
          items={items}
          columnGutter={
            media
              ? media.isXsmallDown()
                ? 16
                : media.isLargeDown()
                ? 32
                : 48
              : null
          }
          columnCount={
            media
              ? media.isSmallDown()
                ? 1
                : media.isMediumDown()
                ? 2
                : 3
              : null
          }
          columnWidth={288}
          as={MasonryItemsListElement}
          itemAs='li'
          itemKey={(item) => item.slug}
          render={masonryRenderFn}
        />
      </MasonryItemsWrapperInner>
    </MasonryItemsWrapper>
  );
}

ProjectList.propTypes = {
  items: T.array
};
